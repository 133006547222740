import React from 'react';
import ReactDOM from 'react-dom';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import { I18nextProvider} from "react-i18next";
import i18n from 'i18next';

import './i18n/config';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const helmetContext = {};

ReactDOM.render(
    <HelmetProvider context={helmetContext}>
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <Helmet htmlAttributes={{ lang: i18n.language }}> </Helmet>
            <App />
          </I18nextProvider>
      </React.StrictMode>
    </HelmetProvider>,
  document.getElementById('root')
);
//const helmet = Helmet.renderStatic();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
