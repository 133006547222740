import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n.use(XHR).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'es',
    debug: true,
    detection: options,
    resources: {
        ar: {
            translations: require('./locales/ar/translations.json')
        },
        en: {
            translations: require('./locales/en/translations.json')
        },
        es: {
            translations: require('./locales/es/translations.json')
        },
        fr: {
            translations: require('./locales/fr/translations.json')
        },
        it: {
            translations: require('./locales/it/translations.json')
        },
        ja: {
            translations: require('./locales/ja/translations.json')
        },
        pt: {
            translations: require('./locales/pt/translations.json')
        },
        ro: {
            translations: require('./locales/ro/translations.json')
        },
        ru: {
            translations: require('./locales/ru/translations.json')
        },
        tr: {
            translations: require('./locales/tr/translations.json')
        },
        vi: {
            translations: require('./locales/vi/translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations',
    supportedLngs: ['ar','en', 'es', 'fr','it','ja','pt','ro','ru','tr','vi']
});

i18n.languages = ['ar', 'en', 'es', 'fr', 'it', 'ja', 'pt', 'ro', 'ru', 'tr', 'vi'];
export default i18n;