import React from 'react';
import balls from './resources/all-balls.jpg';
import { useTranslation } from "react-i18next";
import acceptLanguage from 'accept-language';
import './App.css';
acceptLanguage.languages(['ar', 'en', 'es', 'fr', 'it', 'ja', 'pt', 'ro', 'ru', 'tr', 'vi']);

function App() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <header className="App-header">
        <img src={balls} className="App-logo" alt="logo" />
        <p>
          {t('coming_soon')} 🔥🔥🔥
        </p>
      </header>
    </div>
  );
}
export default App;